import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { ParsedUrlQueryInput } from 'node:querystring';

import { Link, Props as LinkProps } from '@common/atoms/Link';
import { useContextData } from '@common/useContextData';
import { formatInternalRoute, Route } from '@web/routing';

export interface Props extends Omit<LinkProps, 'href'> {
    route: Route;
    query?: ParsedUrlQueryInput;
    className?: string;
}

export const useInternalRoute = (route: Route, query?: ParsedUrlQueryInput) => {
    const { context } = useContextData();
    const __url = useTranslation('url').t;

    return { pathname: formatInternalRoute(route, context.slug, __url), query };
};

export const InternalLink: FC<Props> = ({ children, className, ...props }) => {
    const { pathname, query } = useInternalRoute(props.route, props.query);

    return (
        <Link
            href={{
                pathname,
                query,
            }}
            className={className || ''}
        >
            {children}
        </Link>
    );
};
